import { FILTER_TYPES } from '@/constants/filterTypes';
import type {
  ColumnFilter,
  PaginatedResponse,
  UndecoratedUser,
} from '@witmetrics/api-client';
import { renderUserName } from '@/utils/strings';

type ResponsePagination = PaginatedResponse<any>['pagination'];

export type UserOption = Pick<UndecoratedUser, 'id' | 'firstname' | 'lastname'>;

export interface Pagination
  extends Omit<ResponsePagination, 'pageCount' | 'rowCount'> {
  pageCount?: number;
  rowCount?: number;
}

export function buildColumnFilter<T>(
  column: string,
  comparisonOperator: ColumnFilter['comparisonOperator'],
  value: T
) {
  return {
    column,
    comparisonOperator,
    value,
    filterType: FILTER_TYPES.COLUMN_VALUE,
  };
}

export function buildUserOption({ id, firstname, lastname }: UserOption) {
  return {
    value: id,
    label: renderUserName({ firstname, lastname }),
  };
}
