import Pako from 'pako';
import JSZip from 'jszip';
import {
  type DecoratedFileCollection,
  type DecoratedFileCollectionFile,
  type RequestPagination,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { FILE_TYPES } from '@/constants/fileTypes';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { sortByKey, toObject } from '@/utils/arrays';
import { buildColumnFilter } from '@/utils/filters';
import type { LoadedFile } from '@/providers/LoadedFilesProvider';
import { Mesh } from 'three';
import { TREE_ITEM_TYPES } from '@/constants/treeItemTypes';

type BaseFile = {
  id: number;
  name: string;
};

export const IMAGE_TYPES = [
  FILE_TYPES.JPEG,
  FILE_TYPES.JPG,
  FILE_TYPES.GIF,
  FILE_TYPES.PNG,
];

export const VIDEO_TYPES = [FILE_TYPES.MP4, FILE_TYPES.WEBM, FILE_TYPES.MOV];

export const SCAN_TYPES = [FILE_TYPES.PLY, FILE_TYPES.STL];

export function getFileType(filename: string) {
  return filename.slice(filename.lastIndexOf('.') + 1).toUpperCase();
}

export function getFileExtensions(filename: string) {
  let splitName = filename.toUpperCase().split('.');
  return splitName.slice(1);
}

export function containsExtension(filename: string, extension: string) {
  const extensions = getFileExtensions(filename);
  let isContained = false;
  if (extension.constructor.name === 'String') {
    return extensions.indexOf(extension) !== -1;
  } else if (extension.constructor.name === 'Array') {
    for (let i = 0; i < extension.length; i++) {
      if (extensions.indexOf(extension[i]) !== -1) {
        isContained = true;
        break;
      }
    }
    return isContained;
  }
}

export type Directory = {
  [key: string]: Directory | File;
};

export function parseFileInputPaths(files: File[]) {
  return parseFilePaths(
    files
      .filter((f) => !isIgnoredFile(f.name))
      .map((f) => ({ file: f, path: f.webkitRelativePath }))
  );
}

function parseFilePaths(fileArray: { file: File; path: string }[]) {
  let root: Directory = {};
  // Iterate over the FileList
  for (let i = 0; i < fileArray.length; i++) {
    const file = fileArray[i].file;
    let path = fileArray[i].path;
    if (path.charAt(0) === '/') path = path.slice(1);
    let pathParts = path.split('/');
    let currentLevel: Directory | File = root;

    // Iterate over the parts of the path
    for (let j = 0; j < pathParts.length; j++) {
      const part = pathParts[j];

      // If we're at a file (the last part of the path), add the file to the current level
      if (j === pathParts.length - 1) {
        (currentLevel as Directory)[part] = file;
      } else {
        // If we're at a directory, add it to the current level if it doesn't exist
        if (!(currentLevel as Directory)[part]) {
          (currentLevel as Directory)[part] = {};
        }

        // Move down a level
        currentLevel = (currentLevel as Directory)[part];
      }
    }
  }
  return root;
}

// Inspired by https://mikeyland.netlify.app/post/multi-file-upload-made-easy-how-to-drag-and-drop-directories-in-your-web-app
export async function parseDroppedItems(itemList: DataTransferItemList) {
  if (!('webkitGetAsEntry' in DataTransferItem.prototype)) {
    throw Error('webkitGetAsEntry not supported');
  }
  let fileEntries: FileSystemEntry[] = [];
  // Use BFS to traverse entire directory/file structure
  let queue: (FileSystemEntry | null)[] = [];
  // NOTE: DataTransferItemList is not iterable so need to use a for loop
  for (let i = 0; i < itemList.length; i++) {
    queue.push(itemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (isIgnoredFile(entry?.name)) continue;
    if (entry?.isFile) {
      fileEntries.push(entry);
    } else if (entry?.isDirectory) {
      queue.push(
        ...(await readDirectoryEntry(entry as FileSystemDirectoryEntry))
      );
    }
  }
  const filesArray = await Promise.all(
    fileEntries.map((fileEntry) =>
      parseFileEntry(fileEntry as FileSystemFileEntry)
    )
  );
  return parseFilePaths(filesArray);
}

// Inspired by https://gist.github.com/lpnam0201/e0ca6a19c123b93a439714c8f4f7f6b0
async function readDirectoryEntry(directoryEntry: FileSystemDirectoryEntry) {
  let result: FileSystemEntry[] = [];
  const reader = directoryEntry.createReader();
  const read = async function () {
    let entries = await readEntries(reader);
    if (entries.length > 0) {
      result = [...result, ...entries];
      await read();
    }
  };
  await read();
  return result;
}

function readEntries(reader: FileSystemDirectoryReader) {
  // Makes .readEntries() await-able
  return new Promise<FileSystemEntry[]>((resolve, reject) => {
    reader.readEntries(resolve, reject);
  });
}

function readFileEntryFile(entry: FileSystemFileEntry) {
  // Makes .file() await-able
  return new Promise<File>((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

async function parseFileEntry(entry: FileSystemFileEntry) {
  const file = await readFileEntryFile(entry);
  return { file, path: entry.fullPath };
}

export async function uploadFile(file: File, practiceID: number) {
  const newFile = await API.Files.createNewFile({
    name: file.name,
    practiceID,
  });
  const fileVersion = await API.Files.uploadFileVersion({
    name: file.name,
    fileID: newFile.id,
    fileVersion: file,
  });
  return { file: newFile, fileVersion };
}

export async function createFileCollection(
  name: string,
  directory: Directory,
  practiceID: number,
  parentFileCollectionID?: number
) {
  const newCollection = await API.FileCollections.createNewFileCollection({
    name,
    practiceID,
    parentFileCollectionID,
  });
  let childFiles = 0;
  let childFileCollections = 0;
  Object.keys(directory).forEach((key) => {
    if (directory[key] instanceof File) childFiles++;
    else childFileCollections++;
  });
  return {
    ...newCollection,
    childFiles,
    childFileCollections,
  };
}

export async function deflateBlob(blob: Blob) {
  const buffer = await blob.arrayBuffer();
  const zippedFile = Pako.gzip(buffer);
  if (zippedFile.buffer.byteLength > 0) {
    return zippedFile.buffer;
  } else {
    throw Error('Error compressing file');
  }
}

export async function inflateBlob(blob: Blob) {
  const buffer = await blob.arrayBuffer();
  const inflatedFile = Pako.inflate(buffer);
  if (inflatedFile.buffer.byteLength > 0) {
    return inflatedFile.buffer;
  } else {
    throw Error('Error decompressing file');
  }
}

function isFileType(extensions: string[], name: string) {
  return extensions.includes(getFileType(name));
}

export function isImage(filename: string) {
  return isFileType(IMAGE_TYPES, filename);
}

export function isPdf(filename: string) {
  return isFileType([FILE_TYPES.PDF], filename);
}

export function isVideo(filename: string) {
  return isFileType(VIDEO_TYPES, filename);
}

export function isViewableNonScan(filename: string) {
  return isImage(filename) || isPdf(filename) || isVideo(filename);
}

export function isScan(filename: string) {
  return isFileType(SCAN_TYPES, filename);
}

export function isPLY(filename: string) {
  return isFileType([FILE_TYPES.PLY], filename);
}

export function isSTL(filename: string) {
  return isFileType([FILE_TYPES.STL], filename);
}

export async function uploadFileToFileCollection(
  practiceID: number,
  fileCollectionID: number,
  newFile: File
) {
  const { file, fileVersion } = await uploadFile(newFile, practiceID);
  const fileCollectionFile = await API.FileCollections.addFileCollectionFile(
    fileCollectionID,
    file.id
  );
  return { file, fileCollectionFile, fileVersion };
}

export async function fetchCollectionContents(
  fileCollectionID: number,
  { page, pageSize }: RequestPagination
) {
  // TODO: pagination for collections and files should be separate
  const [fileCollections, files, sequences, path] = await Promise.all([
    fetchChildFileCollections(fileCollectionID, { page, pageSize }),
    fetchFiles(fileCollectionID, { page, pageSize }),
    fetchSequences(fileCollectionID, { page, pageSize }),
    API.FileCollections.fetchFileCollectionPath(fileCollectionID),
  ]);
  return {
    fileCollections,
    files,
    sequences,
    path: sortByKey(path, 'directoryDepth'),
  };
}

export function fetchChildFileCollections(
  fileCollectionID: number,
  { page, pageSize }: RequestPagination
) {
  return API.FileCollections.fetchFileCollections({
    pagination: { page, pageSize },
    filters: [buildParentFilter(fileCollectionID)],
  });
}

export function fetchFiles(
  fileCollectionID: number,
  { page, pageSize }: RequestPagination
) {
  return API.FileCollections.fetchFileCollectionFiles(fileCollectionID, {
    filters: [],
    pagination: { page, pageSize },
  });
}

export function fetchSequences(
  fileCollectionID: number,
  { page, pageSize }: RequestPagination
) {
  return API.FileCollections.fetchFileCollectionSequences(fileCollectionID, {
    filters: [],
    pagination: { page, pageSize },
  });
}

function buildParentFilter(fileCollectionID: number | null = null) {
  return buildColumnFilter(
    'parentFileCollectionID',
    COMPARISON_OPERATORS.EQUALS.value,
    fileCollectionID
  );
}

export function fetchPracticeFileCollections(
  practiceID: number,
  { page, pageSize }: RequestPagination
) {
  return API.Practices.fetchPracticeFileCollections(practiceID, {
    pagination: { page, pageSize },
    filters: [buildParentFilter(null)],
  });
}

export async function fetchFileVersion(fileID: number) {
  const fileVersions = await API.Files.fetchFileVersions(fileID, {
    filters: [],
    pagination: {
      page: 1,
      pageSize: 10, // Should realistically only be 1 file version
    },
  });
  return fileVersions?.results?.length > 0 ? fileVersions.results[0] : null;
}

export async function downloadFileVersion(fileVersionID: number) {
  const result = await API.Files.downloadFileVersion(
    fileVersionID,
    ({ progress }) => {
      // console.log('Progress: ', Math.round(100 * (progress ?? 0)));
    }
  );
  return inflateBlob(result);
}

export async function downloadSharedFile(fileID: number, token: string) {
  const result = await API.SharedContent.downloadSharedFile(fileID, token);
  return inflateBlob(result);
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
  return url;
}

export async function openFileDownloadLink(
  fileVersionID: number,
  filename: string
) {
  const inflated = await downloadFileVersion(fileVersionID);
  const blob = new Blob([inflated], { type: 'application/octet-stream' });
  return downloadBlob(blob, filename);
}

export async function openSharedFileDownloadLink(
  fileID: number,
  filename: string,
  token: string
) {
  const inflated = await downloadSharedFile(fileID, token);
  const blob = new Blob([inflated], { type: 'application/octet-stream' });
  return downloadBlob(blob, filename);
}

export function checkOpenFileCollection(
  fileCollectionFiles: DecoratedFileCollectionFile[],
  openFileIDs: number[],
  fileCollectionID: number
) {
  const fileIDs = fileCollectionFiles
    .filter((f) => f.fileCollectionID === fileCollectionID)
    .map((f) => f.fileID);
  return {
    isAllOpen: fileIDs.every((id) => openFileIDs.includes(id)),
    isSomeOpen: fileIDs.some((id) => openFileIDs.includes(id)),
  };
}

export async function fetchFileCollectionData(fileCollectionID: number) {
  let queue: number[] = [fileCollectionID];
  let files: DecoratedFileCollectionFile[] = [];
  let fileCollections: DecoratedFileCollection[] = [];
  const pagination = { page: 1, pageSize: 100 }; // TODO: Pagination needs to be improved
  while (queue.length > 0) {
    const currentID = queue.shift();
    if (!currentID) continue;
    const data = await fetchCollectionContents(currentID, pagination);
    files.push(...data.files.results);
    fileCollections.push(...data.fileCollections.results);
    data.fileCollections.results.forEach(({ id }) => queue.push(id));
  }
  return {
    fileCollections,
    files,
  };
}

function buildPathString(
  fileCollectionID: number | undefined,
  fileCollections: Record<number, DecoratedFileCollection>,
  rootID: number
): string {
  if (!fileCollectionID || fileCollectionID === rootID) return '';
  const fileCollection = fileCollections[fileCollectionID];
  const parentPath = buildPathString(
    fileCollection.parentFileCollectionID,
    fileCollections,
    rootID
  );
  return `${parentPath}/${fileCollection.name}`;
}

export async function downloadFileCollection(
  fileCollectionID: number,
  onProgress: (update: string) => void
) {
  const zip = new JSZip();
  onProgress('Fetching collection...');
  const [fileCollection, data] = await Promise.all([
    API.FileCollections.fetchFileCollection(fileCollectionID),
    fetchFileCollectionData(fileCollectionID),
  ]);
  const fileCollections = toObject(data.fileCollections, 'id');
  onProgress('Downloading files...');
  const files = await Promise.all(data.files.map(loadFileCollectionFile));
  onProgress('Preparing download...');
  for (const file of files) {
    const path = buildPathString(
      file.fileCollectionID,
      fileCollections,
      fileCollectionID
    );
    zip.file(`${path}/${file.name}`, file.data);
  }
  const blob = await zip.generateAsync({ type: 'blob' });
  downloadBlob(blob, `${fileCollection.name}.zip`);
}

async function loadFileCollectionFile({
  fileID,
  fileCollectionID,
  file,
}: DecoratedFileCollectionFile) {
  const fileVersion = await fetchFileVersion(fileID);
  const inflated = await downloadFileVersion(fileVersion!.id);
  return {
    fileCollectionID,
    name: file.name,
    data: inflated,
  };
}

export function getLoadedScan(
  file: BaseFile,
  loadedFiles: Record<string, LoadedFile>
) {
  const loadedFile = loadedFiles[file.id];
  return loadedFile instanceof Mesh ? loadedFile : null;
}

export function getLoadedImage(
  file: BaseFile,
  loadedFiles: Record<string, LoadedFile>
) {
  const src = loadedFiles[file.id] as string | null;
  return !!src && isImage(file.name) ? src : null;
}

export function getLoadedPdf(
  file: BaseFile,
  loadedFiles: Record<string, LoadedFile>
) {
  const src = loadedFiles[file.id] as string | null;
  return !!src && isPdf(file.name) ? src : null;
}

export function getLoadedVideo(
  file: BaseFile,
  loadedFiles: Record<string, LoadedFile>
) {
  const src = loadedFiles[file.id] as string | null;
  return !!src && isVideo(file.name) ? src : null;
}

export function isIgnoredFile(filename: string | undefined | null) {
  return !!filename?.toLowerCase().includes('ds_store');
}

export function formatTreeID(id: number, type: keyof typeof TREE_ITEM_TYPES) {
  if (type === TREE_ITEM_TYPES.FILE) return `file.${id}`;
  else return `fileCollection.${id}`;
}

export function parseCompositeID(id: string) {
  return parseInt(id.split('.')[1]);
}
